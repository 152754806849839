<template>
  <div>
    <div
      class="h-[24rem] max-w-[28rem] mx-auto bg-gradient-to-b from-orange-400 via-orange-300 to-orange-500"
    >
      <router-link to="/manual">
        <div class="text-center text-slate-400 font-light p-2 w-12 h-12 flex">
          <div>
            <div class="drop-shadow-xl bg-white rounded-full mb-3">
              <!-- <img class="w-8 h-8" src="../assets/img/01.webp" alt="" /> -->
              <svg
                class="w-8 h-8"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 302.967 302.967"
                xml:space="preserve"
                fill="#F16400"
                stroke="#F16400"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <g>
                      <g>
                        <path
                          style="fill: #f16400"
                          d="M151.483,302.967C67.956,302.967,0,235.017,0,151.483S67.956,0,151.483,0 s151.483,67.956,151.483,151.483S235.017,302.967,151.483,302.967z M151.483,24.416c-70.066,0-127.067,57.001-127.067,127.067 s57.001,127.067,127.067,127.067s127.067-57.001,127.067-127.067S221.555,24.416,151.483,24.416z"
                        ></path>
                      </g>
                      <g>
                        <g>
                          <path
                            style="fill: #f16400"
                            d="M116.586,118.12c1.795-4.607,4.297-8.588,7.511-11.961c3.225-3.389,7.114-6.016,11.667-7.898 c4.547-1.904,9.633-2.845,15.262-2.845c7.261,0,13.32,0.995,18.183,2.997c4.857,1.996,8.768,4.482,11.738,7.441 c2.964,2.97,5.091,6.168,6.369,9.584c1.273,3.432,1.915,6.636,1.915,9.595c0,4.901-0.642,8.947-1.915,12.118 c-1.278,3.171-2.866,5.88-4.759,8.131c-1.898,2.252-3.987,4.172-6.293,5.755c-2.295,1.588-4.471,3.171-6.516,4.759 c-2.045,1.583-3.862,3.394-5.445,5.439c-1.588,2.04-2.589,4.601-2.991,7.664v5.831H140.6v-6.908 c0.305-4.395,1.153-8.072,2.529-11.036c1.382-2.964,2.991-5.499,4.83-7.598c1.844-2.089,3.786-3.911,5.836-5.445 c2.04-1.539,3.927-3.073,5.673-4.591c1.73-1.545,3.144-3.225,4.221-5.069c1.071-1.833,1.556-4.15,1.452-6.908 c0-4.705-1.148-8.18-3.454-10.427c-2.295-2.257-5.493-3.378-9.589-3.378c-2.758,0-5.134,0.533-7.131,1.605 s-3.628,2.513-4.911,4.302c-1.278,1.795-2.225,3.894-2.834,6.288c-0.615,2.415-0.919,4.982-0.919,7.756h-22.55 C113.85,127.785,114.791,122.732,116.586,118.12z M162.536,183.938v23.616h-24.09v-23.616H162.536z"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div
            v-if="showGuide"
            class="ml-2 p-2 inset-0 flex items-center justify-center text-orange-700 bg-orange-200 border border-2 border-orange-500 rounded-full opacity-100 transition-opacity duration-300"
          >
            <font-awesome-icon icon="arrow-left" class="me-1" /> Panduan
          </div>
        </div>
      </router-link>
      <div class="mx-auto px-6 py-2">
        <div class="mt-10">
          <div class="items-center justify-between text-center">
            <img
              class="w-16 mx-auto mb-2"
              src="../assets/img/logo.webp"
              alt=""
            />
            <div>
              <span class="text-white text-xl uppercase font-bold">
                N A B E L O
              </span>
            </div>
            <div>
              <span class="text-white text-lg font-semibold">
                Layanan Digital Puskesmas Kota Palu
              </span>
            </div>
            <img
              class="max-w-[20rem] w-5/6 mx-auto mt-10 -mb-5"
              src="../assets/img/hero-image.webp"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="max-w-[28rem] mx-auto"
      style="margin-top: -1px; z-index: -1; position: relative"
    >
      <img class="w-100" src="../assets/img/bottom.webp" alt="" />
    </div>
    <div class="absolute max-w-[25rem] mx-auto inset-x-0 bottom-0 p-3">
      <div class="card mb-2 rounded-xl bg-slate-50 px-4 py-4 shadow-md">
        <h6
          class="text-orange-600 text-md text-center uppercase font-bold mb-2"
        >
          Menu
        </h6>
        <div class="grid grid-cols-4 gap-4">
          <router-link to="/ambil-antrean">
            <div class="text-center text-slate-400 font-light">
              <div
                class="drop-shadow-xl bg-white rounded-2xl items-center justify-between mb-3"
              >
                <img class="w-full" src="../assets/img/01.webp" alt="" />
              </div>
              <div class="text-[10px] font-bold text-orange-600">
                <p>Ambil</p>
                <p>Antrean</p>
              </div>
            </div>
          </router-link>
          <router-link to="/telekonsultasi">
            <div class="text-center text-slate-400 font-light">
              <div
                class="drop-shadow-xl bg-white rounded-2xl items-center justify-between mb-3"
              >
                <img class="w-full" src="../assets/img/05.webp" alt="" />
              </div>
              <div class="text-[10px] font-bold text-orange-600">
                <p>Tele</p>
                <p>Konsultasi</p>
              </div>
            </div></router-link
          >
          <a href="javascript:;" @click="lihatAntrean()">
            <!-- <router-link to="/lihat-antrean"> -->
            <div class="text-center text-slate-400 font-light">
              <div
                class="drop-shadow-xl bg-white rounded-2xl items-center justify-between mb-3"
              >
                <img class="w-full" src="../assets/img/03.webp" alt="" />
              </div>
              <div class="text-[10px] font-bold text-orange-600">
                <p>Antrean</p>
                <p>Saya</p>
              </div>
            </div>
          </a>
          <!-- </router-link> -->
          <router-link to="/jenis-pelayanan">
            <div class="text-center text-slate-400 font-light">
              <div
                class="drop-shadow-xl bg-white rounded-2xl items-center justify-between mb-3"
              >
                <img class="w-full" src="../assets/img/04.webp" alt="" />
              </div>
              <div class="text-[10px] font-bold text-orange-600">
                <p>Jenis</p>
                <p>Pelayanan</p>
              </div>
            </div></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MainPage",
  data() {
    return {
      showGuide: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showGuide = false;
    }, 3000); // Ganti 3000 dengan jumlah milidetik yang diinginkan
  },
  methods: {
    lihatAntrean() {
      const dataNabelo = localStorage.getItem("dataNabelo");
      if (dataNabelo) {
        this.$router.push("/informasi-antrean");
      } else {
        this.$router.push("/lihat-antrean");
      }
    },
  },
};
</script>
<style scoped>
.absolute {
  position: absolute;
}
.hide-guide {
  opacity: 0; /* Teks panduan akan menghilang */
  pointer-events: none; /* Menghindari interaksi dengan teks panduan */
}
</style>