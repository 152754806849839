import { createRouter, createWebHistory } from "vue-router";
import Home from "../pages/Main.vue";

const routes = [
  { path: "/", component: Home },
  {
    path: "/manual",
    component: () => import("../pages/Manual.vue"),
  },
  {
    path: "/registrasi",
    component: () => import("../pages/register/Registrasi.vue"),
  },
  {
    path: "/ambil-antrean",
    component: () => import("../pages/ambil-antrean/AmbilAntrean.vue"),
  },
  {
    path: "/lihat-antrean",
    component: () => import("../pages/informasi-antrean/LihatAntrean.vue"),
  },
  {
    path: "/pilih-puskesmas",
    component: () => import("../pages/ambil-antrean/PilihPuskesmas.vue"),
  },
  {
    path: "/pilih-poli",
    component: () => import("../pages/ambil-antrean/PilihPoli.vue"),
  },
  {
    path: "/jenis-pelayanan",
    component: () => import("../pages/jenis-pelayanan/JenisPelayanan.vue"),
    name: "Jenis Pelayanan",
  },
  {
    path: "/jenis-pelayanan/:id",
    component: () => import("../pages/jenis-pelayanan/JenisPelayanan.vue"),
  },
  {
    path: "/informasi-antrean",
    component: () => import("../pages/informasi-antrean/InformasiAntrean.vue"),
  },
  {
    path: "/telekonsultasi",
    component: () => import("../pages/telekonsultasi/CekNik.vue"),
  },
  {
    path: "/pilih-puskesmas-telekonsultasi",
    component: () => import("../pages/telekonsultasi/PilihFaskes.vue"),
  },
  {
    path: "/ambil-antrean-telekonsultasi",
    component: () => import("../pages/telekonsultasi/AmbilAntrean.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.path === "/ambil-antrean" &&
    from.path !== "/" &&
    from.path !== "/informasi-antrean"
  ) {
    router.replace("/");
  } else if (to.path === "/informasi-antrean" && from.path !== "/") {
    router.replace("/");
  } else if (to.path === "/telekonsultasi" && from.path !== "/") {
    router.replace("/");
  } else {
    next();
  }
});

export default router;
